import { HTMLAttributes, useMemo, useState } from 'react'
import css from 'styled-jsx/css'
import {
  // getFallbackVideoPath,
  imagePath,
  previewImage,
  useCdnBasePath,
  videoPath
} from '@ui/helpers'
import { useIsPreview, useSetting, useVersionCookie } from '@ui/hooks'
// import getConfig from 'next/config'

type ReviewsImagesProps = {
  source: string
  className?: string
}

export const ReviewImage: React.FC<ReviewsImagesProps> = ({ source, className = '' }) => {
  const [isZoomed, setIsZoomed] = useState(false)
  const isPreview = useIsPreview()
  const [sellpageSetting] = useSetting('sellpage')
  const basepath = useCdnBasePath(true) // from IO = true only review and description
  const toggle = () => {
    setIsZoomed(!isZoomed)
  }
  // const config = getConfig().publicRuntimeConfig
  const versionCookie = useVersionCookie()
  const [storeSetting] = useSetting('store')
  const isVpl1 = versionCookie === 'vpl1' || storeSetting?.version === 'vpl1'
  const videoSetting: HTMLAttributes<HTMLVideoElement> = useMemo(() => {
    return isVpl1
      ? {
          'data-src': videoPath(source, basepath),
          className: 'lazy'
        }
      : {}
  }, [versionCookie, storeSetting?.version, source])

  const sourceVideoSetting = useMemo(() => {
    return isVpl1
      ? {
          'data-src': videoPath(source, basepath)
        }
      : {
          src: videoPath(source, basepath)
        }
  }, [versionCookie, storeSetting?.version, source])
  return (
    <>
      <style jsx>{imgZoomStyle}</style>
      <div className={`${isZoomed ? `zoomout` : `zoomin`} new-review-feature}`}>
        {!source.includes('.mp4') ? (
          <picture>
            <source
              {...(isVpl1
                ? {
                    'data-srcset': isPreview ? previewImage(source) : imagePath(source)
                  }
                : { srcSet: isPreview ? previewImage(source) : imagePath(source) })}
            />
            {sellpageSetting?.fallback_img?.[source || ''] && !isPreview ? (
              <source
                {...(isVpl1
                  ? {
                      'data-srcset': imagePath(sellpageSetting?.fallback_img?.[source || ''])
                    }
                  : {
                      srcSet: imagePath(sellpageSetting?.fallback_img?.[source || ''])
                    })}
              />
            ) : (
              <></>
            )}
            <img
              {...(isVpl1
                ? {
                    'data-src': isPreview ? previewImage(source) : imagePath(source)
                  }
                : {
                    src: isPreview ? previewImage(source) : imagePath(source)
                  })}
              title="Review image"
              alt="review"
              role="presentation"
              onClick={toggle}
              className={`${className} ${isVpl1 ? 'lazy' : ''}`}
            />
          </picture>
        ) : (
          <>
            {!isZoomed ? (
              /* eslint-disable */
              <video autoPlay loop muted playsInline onClick={toggle} {...videoSetting}>
                {/* <source src={`${config.cdnVideo || ''}${source}`} type="video/mp4" /> */}
                <source {...sourceVideoSetting} type="video/mp4" />
              </video>
            ) : (
              <video controls loop playsInline onClick={toggle} {...videoSetting}>
                <source {...sourceVideoSetting} type="video/mp4" />
              </video>
            )}
          </>
        )}
      </div>
    </>
  )
}

const imgZoomStyle = css`
  @media screen and (max-width: 768px) {
    .zoomin img,
    .zoomin video {
      height: auto;
      width: 90px;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease;
      cursor: pointer;
    }
    .zoomout img,
    .zoomout video {
      width: 350px;
      height: auto;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease;
      cursor: pointer;
    }
  }

  @media screen and (min-width: 769px) {
    .zoomin img,
    .zoomin video {
      height: auto;
      width: 75px;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease;
      cursor: pointer;
    }
    .zoomout img,
    .zoomout video {
      width: 100%;
      height: auto;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease;
      cursor: pointer;
    }
    .new-review-feature.zoomout img,
    .zoomout video {
      width: 455px;
    }
  }
`
