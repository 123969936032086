import { iconPath } from '@ui/helpers'
import { add, format } from 'date-fns'
import { FC } from 'react'
import css from 'styled-jsx/css'

const PreOrderItem: FC<Types.PlainObject> = () => {
  const datePreOrder = format(add(new Date(), { days: 15 }), 'MMMM dd, yyyy')

  return (
    <div className="pre-order">
      <style jsx global>
        {globalStyle}
      </style>
      <div className="image-box">
        <figure className="image-box__image image">
          <img src={iconPath('truck.svg')} alt="truck" />
        </figure>
        <span className="image-box__text">Pre-Order Item</span>
      </div>
      <div className="pre-order__time">Expected delivery date is {datePreOrder}</div>
    </div>
  )
}

const globalStyle = css.global`
  .pre-order {
    background-color: #e3f2fd;
    border-radius: 8px;
    padding: 10px 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    .image-box {
      display: flex;
      align-items: center;
      padding-bottom: 4px;
      &__text {
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        padding-left: 12px;
        color: #1b1b1b;
      }
    }
    &__time {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #1b1b1b;
    }
  }
  @media screen and (max-width: 768px) {
    .pre-order {
      margin-top: -1px;
    }
  }
  @media screen and (min-width: 769px) {
    .pre-order {
      margin-bottom: 28px;
    }
  }
`

export default PreOrderItem
