/* eslint-disable jsx-a11y/no-onchange */
import css from 'styled-jsx/css'

type DropdownSelectProps = {
  isHorizontal?: boolean
  propertyName: string
  values: string[]
  selectedProperty: string
  onPropertyChanged: (property: string) => void
}

export default function DropdownSelect({
  isHorizontal = true,
  propertyName,
  values,
  selectedProperty,
  onPropertyChanged
}: DropdownSelectProps) {
  return (
    <div className="dropdown-select">
      <style jsx global>
        {globalStyle}
      </style>
      <div className={`field ${isHorizontal ? 'is-horizontal' : ''}`}>
        <div className="field-label is-normal">
          <label className="label is-capitalized has-text-left">{propertyName}</label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <div className="select">
                <select
                  value={selectedProperty}
                  onChange={(e) => onPropertyChanged(e.target.value)}
                >
                  {values.map((value, idx) => (
                    <option key={idx} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const globalStyle = css.global`
  .dropdown-select {
    &:not(:last-child) {
      margin-bottom: 15px;
    }

    .select {
      height: auto;

      select {
        height: auto;
        padding: 10px 40px 10px 10px;
        border-color: #4a4a4a;
        font-size: 12px;
        font-weight: bold;
        line-height: 18px;
        text-transform: capitalize;
      }
    }
  }
`
