import css from 'styled-jsx/css'

export const globalStyle = css.global`
  .cart {
    $padding: 30px;
    $padding-mobile: 18px;

    &.gp02 {
      .modal {
        .modal-card {
          .modal-card-head {
            padding: 6px 10px 5px 12px;
            box-sizing: border-box;

            .modal-card-title {
              line-height: 32px;
            }

            button.delete {
              width: 38px;
              height: 38px;
              min-height: 38px;
              min-width: 38px;
            }
          }
          &__policy {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #e8f5e9;
            padding: 2px 0px;

            &--icon {
              width: 16px;
              margin-right: 6px;
            }

            &--text {
              font-size: 15px;
              line-height: 24px;
              font-weight: 400;
              color: #1b1b1b;
            }

            &--highlight {
              font-weight: 700;
              color: #1d9f54;
            }
          }
          .modal-card-body {
            .order-variants {
              padding-bottom: 16px;
              &.has-upsell {
                padding-bottom: 8px;
              }
            }
            .scrollable-content {
              max-height: calc(100vh - 293px);
              max-height: calc((var(--vh, 1vh) * 100) - 293px);
              padding: 16px 12px 0px;
              .upsells {
                margin-bottom: 8px;
                margin-top: 0px;
                box-sizing: border-box;
                &__button {
                  padding: 7px 10px;
                  border: none;
                }
              }
            }
            .fixed-bottom {
              .total {
                margin: 10px 0px;

                .price {
                  margin-left: 14px;
                  font-size: 15px;
                  line-height: 24px;
                  font-weight: 700;
                  color: #1b1b1b;
                }
              }
            }
          }
        }
      }

      @media screen and (min-width: 768px) {
        .modal {
          .modal-card {
            .modal-card-head {
              padding: 26px 30px 26px 30px;

              button.delete {
                width: 40px;
                height: 40px;
                min-height: 40px;
                min-width: 40px;
              }
            }

            &__policy {
              padding: 4px 0px;
              &--icon {
                width: 18px;
              }
            }

            .modal-card-body {
              .order-variants {
                padding-bottom: 0px;
                &.has-upsell {
                  padding-bottom: 0px;
                }
              }
              .scrollable-content {
                max-height: calc(100vh - 369px);
                padding: 12px 24px 0px;

                .upsells {
                  margin-bottom: 16px;
                  margin-top: -6px;
                  &__button {
                    padding: 8px 10px;
                  }
                }
              }
              .fixed-bottom {
                hr {
                  margin-bottom: 18px;
                }
                .total {
                  margin: 0px 0px 20px;
                }
              }
            }
          }
        }
      }
    }

    .cart-button {
      position: fixed;
      right: 1.5rem;
      bottom: 2rem;
      z-index: 9;

      .button {
        border-radius: 50%;
        padding: 24px 14px;
        width: 52px;
        height: 54px;

        img {
          width: 22px;
          height: 22px;
          object-fit: cover;
        }
      }

      .badge {
        position: absolute;
        top: -12px;
        right: -12px;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        border: solid 4px white;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        cursor: pointer;
      }

      &.cart-v12u {
        right: 12px;
        bottom: 32px;
        z-index: 9;
        display: flex;
        @media screen and (max-width: 768px) {
          bottom: 24px;
        }
        .button {
          box-shadow: 0px 6.4px 14.4px rgba(101, 115, 129, 0.13);
          border-radius: 73px;
          padding: 10px 18px 10px 18px;
          width: 196px;
          height: 44px;
          &__title {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            color: rgba(255, 255, 255, 1);
            margin-right: 8px;
          }
          img {
            width: 20px;
            height: 20px;
            object-fit: cover;
            margin-right: 4px;
          }
        }
        .badge {
          position: static;
          border: none;
          line-height: 22px;
          width: 20px;
          height: 20px;
          min-width: 20px;
          border-radius: 50%;
          display: flex;
          font-size: 11px;
          font-weight: 700;
          justify-content: center;
          align-items: center;
          color: white;
          cursor: pointer;
        }
      }
    }

    .modal {
      z-index: 1000;

      .modal-card {
        height: 100vh;
        max-height: 100vh;
        max-width: 480px;
        margin-right: 0;

        @media screen and (max-width: 768px) {
          margin-left: 0;
          max-width: 100vw;
          width: 100vw;
        }

        .modal-card-head {
          background-color: white;
          // border-bottom: 0;
          border-radius: 0;
          padding: 40px $padding $padding;

          @media screen and (max-width: 768px) {
            padding: 8px 20px;
          }

          .modal-card-title {
            font-size: 20px;
            font-weight: bold;

            @media screen and (max-width: 360px) {
              font-size: 18px;
              font-weight: bold;
            }
          }

          .delete {
            font-size: 40px;
            height: 40px;
            max-height: 40px;
            max-width: 40px;
            min-height: 40px;
            min-width: 40px;
            width: 40px;
          }
        }

        .modal-card-body {
          padding: 0;
          position: relative;

          &.disabled {
            pointer-events: none;
          }

          .scrollable-content {
            max-height: calc(100vh - 363px);
            padding: $padding $padding 20px;
            position: absolute;
            width: 100%;
            top: 0;
            right: 0;
            left: 0;
            z-index: 100;
            overflow-x: hidden;
            overflow-y: auto;

            .upsells {
              background: #f6f8f9;
              border: 1px dashed #1d9f54;
              border-radius: 4px;
              display: flex;
              padding: 8px 10px;
              align-items: center;
              margin-top: 24px;
              @media screen and (max-width: 768px) {
                margin: 0 0 24px;
              }
              &__text {
                font-size: 15px;
                line-height: 24px;
                color: #1d9f54;
                padding-right: 8px;
                margin-right: auto;
                font-weight: 500;
                &__bold {
                  font-weight: 700;
                }
                &__semi-bold {
                  font-weight: 600;
                }
              }
              &__button {
                background: #1d9f54;
                border: 1px solid #1d9f54;
                border-radius: 4px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 8px 10px;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                color: #ffffff;
                margin-left: auto;
              }
            }
          }

          .fixed-bottom {
            // background-color: #FFFFFF;
            padding: 0 $padding $padding;
            position: absolute;
            width: 100%;
            bottom: 0;
            z-index: 101;
            .total {
              margin-bottom: 20px;
              text-align: right;

              .price {
                font-weight: bold;
                margin-left: 24px;
              }
            }
          }

          @media screen and (max-width: 768px) {
            .scrollable-content {
              max-height: calc(100vh - 256px);
              max-height: calc((var(--vh, 1vh) * 100) - 256px);

              padding: 20px 12px;
            }

            .fixed-bottom {
              hr {
                margin-bottom: 0;
              }
              background-color: white;
              padding: 0 $padding-mobile $padding-mobile;
              position: absolute;
              width: 100%;
              bottom: 0;
              z-index: 101;

              @media screen and (max-width: 360px) {
                .button {
                  font-size: 14px;
                }

                .is-divider::after {
                  font-size: 12px;
                }
              }

              .is-divider::after {
                @media screen and (max-width: 360px) {
                  font-size: 0.6rem;
                }
              }

              .total {
                margin: 12px 0;
                text-align: right;

                .price {
                  font-weight: bold;
                  margin-left: 24px;
                }
              }
            }
          }
        }

        .label__icon {
          display: inline-block;

          &:not(:last-child) {
            margin-left: 10px;
          }

          img {
            width: unset;
            height: 30px;
          }
        }
      }
    }
  }
`

export const orderVariantStyle = css.global`
  .order-variants {
    @media screen and (max-width: 769px) {
      padding-bottom: 24px;
      hr {
        margin: 16px 0;
      }
    }
    .variant-item:last-child {
      hr {
        display: none;
      }
    }
    &__bottom {
      margin-bottom: 0;

      @media screen and (min-width: 769px) {
        margin-bottom: 20px;
      }
    }

    &__summary {
      margin-top: 32px;
      .item {
        display: flex;
        justify-content: flex-end;

        &__label {
          text-align: right;
          //font-size: 16px;
          line-height: 24px;

          &--refund {
            color: #eb5757;
            margin-top: 8px;
            display: flex;
            align-items: center;
          }

          &--bold {
            font-weight: 600;
            //font-size: 16px;
            line-height: 32px;
            margin-top: 8px;
          }
        }

        &__number {
          text-align: right;
          min-width: 80px;

          /* margin-left: 60px; */
          //font-size: 16px;
          line-height: 24px;
          font-weight: 600;

          &--refund {
            color: #eb5757;
            margin-top: 8px;
          }

          &--bold {
            font-weight: 600;
            //font-size: 16px;
            line-height: 32px;
            margin-top: 8px;
          }
        }

        &__separator {
          @media screen and (min-width: 769px) {
            display: none;
          }
        }
      }

      .countdown {
        color: var(--secondary);
        display: inline-block;
        font-weight: bold;
        min-width: 44px;
      }

      @media screen and (max-width: 768px) {
        margin-bottom: 3rem;
      }
    }

    .modal {
      @media screen and (max-width: 768px) {
        justify-content: flex-end;
      }
    }

    .price {
      &__secondary {
        text-decoration: line-through;
      }
    }

    .remove {
      text-decoration: underline;
    }

    .refund-hint {
      height: 20px;
      width: 20px;
      margin-right: 0.5rem;
      //margin-top: 10px;
    }

    .max-witdh__mobile {
      @media screen and (max-width: 321px) {
        max-width: 140px;
      }
    }

    .variant {
      &--top {
        margin-bottom: -12px !important;
        display: inline-flex;
      }

      &__image {
        border: solid 1px #e8e8e8;
        border-radius: 5px;

        img {
          border-radius: 4px;
        }
      }

      &__title {
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
        margin-bottom: 8px;
      }

      &__property {
        display: flex;
        flex-wrap: wrap;
        font-size: 12px;
        line-height: 24px;
        //margin-right: 24px;
        text-transform: capitalize;

        @media screen and (max-width: 786px) {
          margin-right: 0;
        }
      }

      @media screen and (max-width: 575px) {
        .property,
        p {
          max-width: 230px;
        }
      }

      &__remove {
        float: right;

        @media screen and (max-width: 768px) {
          float: initial;
        }

        .button {
          font-size: 12px;
          line-height: 18px;
          padding: 0;

          &:hover {
            background-color: transparent;
          }
        }
      }

      .btn-remove-mobile {
        text-align: center;
      }

      @media screen and (max-width: 768px) {
        &__quantity {
          /* margin-top: 4px; */
          margin-top: -0.75rem;

          .field.has-addons {
            margin-right: 0.75rem;
            border: 1px solid #dbdbdb;
            border-radius: 4px;
            justify-content: space-between;
          }

          p.control a.button {
            border: 0px;
          }

          input.input {
            border: 0px;
          }
        }

        &__quantity > div {
          flex: 33.33%;
        }
      }

      @media screen and (min-width: 375px) and (max-width: 450px) {
        &__quantity {
          .field.has-addons {
            margin-right: 0.5rem;
          }
        }
      }

      @media screen and (max-width: 375px) {
        &__quantity {
          .field.has-addons {
            margin-right: 0px;
          }
        }
      }

      &__quantity {
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 786px) {
          &.desktop {
            display: block;

            &.is-flex {
              display: none !important;
            }
          }

          &.mobile {
            display: none;

            .is-flex {
              display: flex;
            }
          }
        }

        @media screen and (min-width: 787px) {
          &.desktop {
            display: block;
          }

          &.mobile {
            display: none !important;
          }
        }
      }

      &__price {
        font-size: 16px;
        min-width: 136px;
        text-align: right;

        @media screen and (max-width: 360px) {
          text-align: left;
        }

        .compared {
          font-size: 12px;
          margin-right: 14px;
          color: var(--grey);
        }
      }
      .upsell-save {
        font-weight: 600;
        font-size: 13px;
        line-height: 24px;
        color: #1d9f54;
      }
    }
    .size {
      &--small {
        display: flex;
        justify-content: space-between;
      }
    }
  }
`
