import { useState } from 'react'
import { useInterval } from '@ui/hooks'

type RandomNumberProps = {
  initValue: number
  type?: 'random' | 'up' | 'down'
}

export default function RandomNumber({ initValue, type = 'random' }: RandomNumberProps) {
  const [currentValue, setCurrentValue] = useState(initValue)
  useInterval(() => {
    const x = Math.floor(Math.random() * 10)
    switch (type) {
      case 'random':
        // eslint-disable-next-line no-case-declarations
        const multiplier = Math.floor(Math.random() - 0.5) * 2 + 1
        setCurrentValue(Math.max(currentValue + multiplier * x, 0))
        break
      case 'up':
        setCurrentValue(currentValue + x)
        break
      case 'down':
        setCurrentValue(Math.max(currentValue - x, 0))
        break
    }
  }, 3000)
  return <span className="random-number">{currentValue}</span>
}
