import { CustomerAddress } from '@libs/client/order/services'
import axios from 'axios'

export const getAddress = async (id: string) => {
  try {
    const data = await axios.get(`/sync-address/${id}`)
    return data?.data as CustomerAddress
  } catch (error) {
    console.error('Cannot get data in shipping', error)
  }
}
