import { FC, FormEvent, useState } from 'react'
import css from 'styled-jsx/css'
import StarRating from '../shared/StarRating'
import { useNotiContext, REMOVE_ALL_AND_ADD } from '@notify'
import { FileUploader } from '../shared/FileUploader'

type ReviewsProps = {
  open: boolean
  toggleModal: () => void
}

const ReviewModal: FC<ReviewsProps> = ({ open = false, toggleModal }: ReviewsProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [rating, setRating] = useState(5)
  const [name, setName] = useState('')
  const [title, setTitle] = useState('')
  const [review, setReview] = useState('')
  const { notiDispatch } = useNotiContext()
  const [reviewImageBlob, setReviewImageBlob] = useState(null)

  const onFilesChanged = async (files: any) => {
    setReviewImageBlob(files[0])
  }

  const onSubmit = (event: FormEvent<any>) => {
    event.preventDefault()
    setIsSubmitting(true)
    setTimeout(() => {
      toggleModal && toggleModal()
      setRating(5)
      setName('')
      setTitle('')
      setReview('')
      setIsSubmitting(false)
      // noti({
      //   content: 'Thanks for the review! Your review will be verified by the administrator.',
      //   type: 'is-primary',
      //   timeout: 10
      // })
      notiDispatch({
        type: REMOVE_ALL_AND_ADD,
        payload: {
          content: 'Thanks for the review! Your review will be verified by the administrator.',
          type: 'is-primary',
          timeout: 10
        }
      })
    }, 1000)
  }
  return (
    <>
      <style jsx>{globalStyle}</style>
      <style jsx>{fileUploadStyle}</style>
      <div
        className={`modal slide-right ${open ? 'is-active' : ''}`}
        style={{
          visibility: open ? 'visible' : 'hidden'
        }}
      >
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head has-background-white">
            <p className="modal-card-title">Write your review</p>
            <button className="delete" aria-label="close" onClick={toggleModal} />
          </header>
          <section className="modal-card-body">
            <StarRating
              className={starStyle.className}
              editable={true}
              rating={rating}
              onChange={(idx) => setRating(idx)}
            />
            {starStyle.styles}
            <form onSubmit={onSubmit}>
              <div className="field">
                <label className="label">
                  Your name
                  <div className="control">
                    <input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      className="input"
                      required
                    />
                  </div>
                </label>
              </div>
              <div className="field">
                <label className="label">
                  Title
                  <div className="control">
                    <input
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      type="text"
                      className="input"
                      required
                    />
                  </div>
                </label>
              </div>
              <div className="field">
                <label className="label">
                  Write your review
                  <div className="control">
                    <textarea
                      value={review}
                      onChange={(e) => setReview(e.target.value)}
                      className="textarea"
                      required
                    />
                  </div>
                </label>
              </div>
              <div className={`image-container`}>
                <div
                  className={`${
                    reviewImageBlob ? 'after-upload-boarder' : 'before-upload-boarder'
                  }`}
                >
                  {/* File upload */}
                  <FileUploader
                    className="img-wrapper"
                    height="90px"
                    width="90px"
                    multiple={true}
                    acceptFileType="image/*"
                    showIcon={!reviewImageBlob}
                    onFilesChanged={(f) => onFilesChanged(f)}
                  />
                  {reviewImageBlob && <img src={URL.createObjectURL(reviewImageBlob)} alt="" />}
                </div>
              </div>
              <button
                type="submit"
                className={`button--cta button is-primary is-fullwidth-mobile ${
                  isSubmitting ? 'is-loading' : ''
                }`}
              >
                Submit your review
              </button>
            </form>
          </section>
        </div>
      </div>
    </>
  )
}

const globalStyle = css`
  .modal {
    .modal-card {
      height: 100vh;
      max-height: 100vh;
      max-width: 480px;
      margin-right: 0;

      @media screen and (max-width: 768px) {
        margin-left: 0;
        max-width: 100vw;
        width: 100vw;
      }

      .modal-card-head {
        border-bottom: 0;
        border-radius: 0;
        padding: 40px 30px 10px;

        @media screen and (max-width: 768px) {
          padding: 18px 20px;
        }

        .modal-card-title {
          font-size: 20px;
          font-weight: bold;
        }

        .delete {
          font-size: 40px;
          height: 40px;
          max-height: 40px;
          max-width: 40px;
          min-height: 40px;
          min-width: 40px;
          width: 40px;
        }
      }

      .modal-card-body {
        padding: 10px 30px;

        @media screen and (max-width: 768px) {
          padding: 18px 20px;
        }
      }
    }

    .field {
      margin-bottom: 40px;

      .input,
      .textarea {
        border-radius: 0;
        border-top: 0;
        border-right: 0;
        border-left: 0;
        box-shadow: none;
      }
    }
  }
`

const fileUploadStyle = css`
  .image-container {
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 8px;
    height: 90px;
    width: 90px;
    margin-bottom: 30px;

    .before-upload-boarder {
      border-radius: 8px;
      height: 90px;
      width: 90px;
      border: 3px dashed #e0e3e9;
      background-color: #f9fafb;
    }

    .after-upload-boarder {
    }

    img {
      height: 90px;
      width: 90px;
      object-fit: cover;
      border-radius: 8px;
    }

    :global(.file-uploader) {
      margin: auto;
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0;
      border: 0;
      border-radius: 8px;
      left: 0;
      height: 90px;
      color: #fff;
      background-size: cover;
      width: 90px;

      align-items: center;
      justify-content: center;

      display: flex;
      flex-direction: column;

      :global(.file-drop-target) {
        background: rgba(93, 93, 93, 0);
        color: #fff;
        :global(.icon) {
          fill: #fff;
        }
      }
    }
  }
`

const starStyle = css.resolve`
  .star-rating {
    height: 37px;
    margin-bottom: 60px;
    @media screen and (max-width: 768px) {
      height: 20px;
      margin-bottom: 30px;
    }
  }
`

export default ReviewModal
