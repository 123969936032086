import { iconPath } from '@ui/helpers'
import {
  useIsPreview,
  useRatings,
  useReviews,
  useSetting,
  useUpdateEffect,
  useVersionCookie
} from '@ui/hooks'
import { FC, useState } from 'react'
import css from 'styled-jsx/css'
import axios from 'axios'
import StarRating from '../shared/StarRating'
import ReviewModal from './ReviewModal'
import { ReviewImage } from './ReviewImage'
import React from 'react'
import { page_review, page_reviews } from '@libs/common'

type ReviewsProps = {
  perPage?: number
  className?: string
}

interface RatingsReviewResponse {
  total?: number
  reviews?: page_reviews
  ratio_rating?: number
}

const Reviews: FC<ReviewsProps> = ({ perPage = 3, className }: ReviewsProps) => {
  const isPreview = useIsPreview()
  const [sellpageReviews] = useReviews()
  const [sellpageRatings] = useRatings()
  const [sellpageSetting] = useSetting('sellpage')
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [cPerPage, setCPerPage] = useState(perPage)
  const version = useVersionCookie()
  const [storeSetting] = useSetting('store')
  const isVpl1 = version == 'vpl1' || storeSetting?.version == 'vpl1'

  //state for rating new-review-feature
  const [dataRatingsReal, setDataRatingsReal] = useState<RatingsReviewResponse>({
    total: sellpageRatings?.total_rating ?? 0,
    reviews: sellpageReviews,
    ratio_rating: sellpageRatings?.ratio_rating ?? 0
  })

  const [ratingPreview, setRatingPreview] = useState<RatingsReviewResponse>({
    total: [...(sellpageReviews || []), ...(sellpageRatings?.user_ratings || [])]?.length ?? 0,
    reviews: [...(sellpageReviews || []), ...(sellpageRatings?.user_ratings || [])]?.slice(
      0,
      3
    ) as page_reviews,
    ratio_rating: sellpageRatings?.ratio_rating ?? 4.9
  })

  const showMore = () => {
    setIsLoading(true)
    setTimeout(() => {
      setCPerPage(cPerPage + 3)
      setIsLoading(false)
    }, 300)
  }

  const handleFetchReview = async () => {
    if (isPreview) {
      setRatingPreview({
        ...ratingPreview,
        reviews: [...sellpageReviews, ...sellpageRatings?.user_ratings]?.slice(
          0,
          cPerPage
        ) as page_review[]
      })
    } else {
      try {
        setIsLoading(true)
        const response = await axios.get<RatingsReviewResponse>(
          `/api/pages/${sellpageSetting?.settings?.general?.id}/ratings?skip=0&take=${cPerPage}`
        )
        setDataRatingsReal({ ...dataRatingsReal, reviews: response?.data?.reviews })
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const dataRatings = isPreview ? ratingPreview : dataRatingsReal

  const toggleModal = () => setIsOpen(!isOpen)

  useUpdateEffect(() => {
    handleFetchReview()
  }, [cPerPage])
  return (
    <>
      {!!sellpageReviews.length && (
        <div className={`sellpageReviews ${className} new-review-feature`}>
          <style jsx>{scopedStyle}</style>
          <style jsx>{imgZoomStyle}</style>
          <>
            <div className="header-rating">
              <div className="header-rating__left">
                <div className="title--large">Reviews</div>
              </div>
              <div className="header-rating__right">
                <div className="header-rating__details rating-details">
                  <div className="rating-details__score">
                    {dataRatings?.ratio_rating?.toFixed(1) ?? 0}
                  </div>
                  <div className="rating-details__star">
                    <StarRating
                      rating={dataRatings?.ratio_rating ?? 0}
                      className="has-text-left rating-star"
                    />
                    <p className="rating-details__total">
                      Based on <b>{dataRatings?.total} ratings</b>
                    </p>
                  </div>
                </div>
                <a
                  className="sellpageReviews--button button is-primary is-fullwidth-mobile"
                  onClick={toggleModal}
                >
                  <figure className="image is-18x18">
                    {isVpl1 ? (
                      <img
                        data-src={iconPath('pen.svg' || '')}
                        className={`lazyload lazypreload`}
                        alt=""
                      />
                    ) : (
                      <img src={iconPath('pen.svg' || '')} alt="" />
                    )}
                  </figure>
                  <span className="write-content-button">Write your review</span>
                </a>
              </div>
            </div>

            <hr className="hr" />

            <div className="review-content">
              {dataRatings?.total
                ? dataRatings?.reviews?.map((review, idx) => (
                    <div key={`review-${idx}`} className="review-item">
                      <div className="review-box">
                        <div className="review-box--name">{review?.name}</div>
                        <StarRating
                          rating={review?.rating}
                          className="style-inreview-item star-small-desktop"
                        />

                        <div className="is-show-desktop">
                          <StarRating rating={review?.rating} className="style-inreview-item" />
                        </div>
                      </div>
                      <div className="review-item__content">
                        <div className="review-box--verify">
                          <div className="is-flex align-items-center">
                            {isVpl1 ? (
                              <img
                                data-src={iconPath('check_green.svg' || '')}
                                className={`review-box--checked lazyload lazypreload`}
                                alt=""
                              />
                            ) : (
                              <img
                                src={iconPath('check_green.svg' || '')}
                                className={`review-box--checked`}
                                alt=""
                              />
                            )}
                            <small className="small-text-verify">Verified Buyer</small>
                          </div>
                        </div>

                        {review?.title ? (
                          <div className="review-item-content">
                            <div className="review-item-content--title is-show-mobile">
                              {review?.title ?? ''}
                            </div>
                            <div className="reverse-content">
                              <div className="review-item-content--title is-show-desktop">
                                {review?.title ?? ''}
                              </div>
                              <div className="switch-position">
                                <div className="review-item-content--content">
                                  {review?.description ?? ''}
                                </div>
                                {review?.images?.length >= 1 && (
                                  <div className="review-item-content--image">
                                    <ReviewImage source={review?.images[0]} />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      {idx == (dataRatings?.reviews?.length || 0) - 1 ? (
                        ''
                      ) : (
                        <hr
                          className={`review-item-content--divider ${
                            !review?.title ? 'is-divider-ratings' : ''
                          } `}
                        />
                      )}
                    </div>
                  ))
                : ''}
            </div>
            <div className="show-more-button">
              <button
                className={`button button-load button--cta is-primary is-outlined ${
                  cPerPage >= (dataRatings?.total ?? 0) ? 'is-hidden' : ''
                } ${isLoading ? 'is-loading' : ''}`}
                onClick={showMore}
              >
                Show more
              </button>
            </div>
          </>
        </div>
      )}
      <ReviewModal open={isOpen} toggleModal={toggleModal} />
    </>
  )
}

const scopedStyle = css`
  .sellpageReviews {
    &--button {
      padding: 15px;
      height: auto;
      line-height: 28px;

      .image {
        margin-right: 10px;
      }
    }

    &--title {
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      margin-right: 17px;
    }
  }

  .review {
    margin-bottom: 13px;

    &--content {
      margin-bottom: 45px;
    }

    &--title {
      font-weight: 700;
      margin-bottom: 8px;
    }
  }

  .hr {
    margin-bottom: 60px;
    background-color: #e0e3e9;
  }

  .show-more-button {
    text-align: center;
    margin-top: 8px;

    button {
      padding-right: 25px;
      padding-left: 25px;

      &:hover,
      &:focus {
        background-color: transparent !important;
        color: var(--text) !important;
      }

      &.is-loading {
        background-color: var(--text) !important;
      }
    }
  }

  .new-review-feature {
    padding-top: 36px !important;
    .header-rating {
      &__left {
        .title--large {
          margin-bottom: 24px;
          text-align: center;
          font-size: 32px;
          font-weight: 700;
          line-height: 48px;
        }
      }
      &__right {
        margin-bottom: 48px;
        .sellpageReviews--button {
          padding: 14px 24px;
        }
      }

      .is-18x18 {
        width: 18px;
        height: 18px;
      }

      .write-content-button {
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
      }

      .rating-details {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
        &__score {
          font-size: 48px;
          font-weight: 600;
          line-height: 62px;
          margin-right: 16px;
        }
        &__total {
          margin-top: 2px;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
        }
        &__star {
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          :global(.rating-star) {
            height: 21px;
          }
        }
      }
    }

    .show-more-button {
      margin-top: 40px;
    }

    & .sellpageReviews--title {
      margin-right: 12px;
    }

    .align-items-center {
      align-items: center;
    }

    .is-show-desktop {
      display: none;
    }

    .button.button-load {
      display: block;
      width: 100%;
      padding: 12px 24px;
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;
      color: var(--gray-8, #363636);

      &:focus {
        box-shadow: none;
      }
    }

    .hr {
      display: none;
    }

    :global(.star-small-mobile) {
      display: none !important;
    }

    .review-content {
      margin-top: 24px;

      &__verify {
        display: flex;
        align-items: center;
        &--icon {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
        &--text {
          font-size: 13px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
        }
      }

      .review-variant {
        display: inline;

        &__children {
          display: inline-block;
          position: relative;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          padding-right: 10px;
          margin-right: 10px;
          &::after {
            position: absolute;
            content: '';
            width: 1px;
            height: 14px;
            top: 5px;
            right: 0;
            background-color: #ababab;
          }
          &:last-child {
            &::after {
              display: none;
            }
          }
        }
      }

      .properties-style-desktop {
        display: none;
      }
      .properties-style-mobile {
        display: block;
      }
    }

    .review-item {
      padding-top: 32px;
      .review-box {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--name {
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          padding-right: 24px;

          color: #363636;
        }

        &--verify {
          .small-text-verify {
            font-size: 13px;
            font-weight: 400;
            line-height: 24px;
          }
        }

        &--checked {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
      }
      &:first-child {
        padding-top: 0px;
      }
      &:last-child {
        padding-bottom: 0px;
      }

      .switch-position {
        display: flex;
        flex-direction: column-reverse;
      }
    }
    .review-item-content {
      margin-top: 16px;
      &--content {
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;

        color: #363636;
      }

      &--title {
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        color: #363636;
      }

      &--divider {
        background-color: #e0e3e9;
        margin-bottom: 0px;
        margin-top: 32px;
      }
      &--image {
        margin-top: 8px;
      }
    }

    .is-divider-ratings {
      margin-top: 24px;
    }

    @media screen and (min-width: 768px) {
      .header-rating {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        &__left {
          display: flex;
          align-items: center;
          width: 20%;
          padding-right: 24px;
          .title--large {
            margin-bottom: 0px;
            padding: 0px;
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
          }
        }
        &__right {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 0px;
          width: 80%;
          .sellpageReviews--button {
            padding: 13px 24px;
          }
        }
        .rating-details {
          margin-bottom: 0px;
          &__score {
            font-size: 60px;
            font-weight: 600;
            line-height: 72px; /* 120% */
            letter-spacing: -2.4px;
          }
          &__total {
            margin-top: 4px;
          }
        }
      }

      .hr {
        margin-bottom: 24px;
        margin-top: 12px;
        display: block;
      }

      .is-show-desktop {
        display: block;
      }
      .is-show-mobile {
        display: none;
      }

      :global(.star-small-desktop) {
        display: none !important;
      }
      :global(.star-small-mobile) {
        display: block !important;
        margin-top: 5px;
      }

      .review-item-content {
        &--divider {
          margin-top: 24px;
        }
      }

      .is-divider-ratings {
        margin-top: 10px;
      }

      .review-content {
        margin-top: 0px;

        .properties-style-desktop {
          display: block;
          margin-top: 8px;
        }
        .properties-style-mobile {
          display: none;
        }
      }

      .review-item {
        padding-top: 24px;
        &__content {
          display: flex;

          .review-item-content {
            width: 80%;
            margin-top: 0px;

            &--title {
              margin-top: -2px;
              margin-bottom: 8px;
            }

            .reverse-content {
              display: flex;
              flex-direction: column;

              .switch-position {
                display: flex;
                flex-direction: column-reverse;
              }
            }

            &--image {
              margin-bottom: 1px;
            }
          }
        }

        .review-box {
          display: flex;
          align-items: start;
          justify-content: flex-start;
          margin-bottom: 0px;

          &--name {
            width: 20%;
          }

          &--verify {
            width: 20%;
          }
        }
      }
    }
  }
`

const imgZoomStyle = css`
  .zoomin img {
    height: 90px;
    width: 90px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
    cursor: pointer;
  }
  .zoomin img:hover {
    width: 350px;
    height: 350px;
  }
`

export default Reviews
