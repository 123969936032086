import { FC } from 'react'
import css from 'styled-jsx/css'

const PreOrderSlotLeft: FC<Types.PlainObject> = () => {
  return (
    <div className="slot-left">
      <style jsx global>
        {globalStyle}
      </style>
      <span className="slot-left__bold">72 people</span> have pre-ordered this product. Only{' '}
      <span className="slot-left__bold text-red">28 products left!</span>
    </div>
  )
}

const globalStyle = css.global`
  .slot-left {
    font-weight: 400;
    font-size: 13px;
    line-height: 19.5px;
    color: #1b1b1b;
    &__bold {
      font-weight: 700;
      &.text-red {
        color: #ef5350;
      }
    }
    @media screen and (min-width: 769px) {
      line-height: 24px;
    }
  }
`

export default PreOrderSlotLeft
