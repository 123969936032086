import { freeze, unFreeze } from '@ui/helpers'
import React, { useEffect, useState } from 'react'
import css from 'styled-jsx/css'

type CountdownNotifyModalProps = {
  open: boolean
  percentOff: number
  countdownText?: string
}

export const CountdownNotifyModal: React.FC<CountdownNotifyModalProps> = ({
  open,
  percentOff,
  countdownText
}) => {
  const [visible, setVisible] = useState(open)
  useEffect(() => {
    setVisible(open)
  }, [open])
  useEffect(() => {
    if (visible) {
      freeze()
    } else {
      unFreeze()
    }
  }, [visible])
  return (
    <>
      <style jsx>{CountdownNotifyModalStyles}</style>
      <div className={`modal modal-wrapper ${visible ? ' is-active' : ''}`}>
        <div className="modal-background" onClick={() => setVisible(false)}></div>
        <div className="modal-content">
          <div className="modal-content__title">
            {countdownText === 'Christmas Sale' ? (
              <>
                BIGGEST HOLIDAY <br /> SALE - {Math.floor(percentOff)}% OFF!
              </>
            ) : (
              <>
                CLEARANCE SALE <br /> {Math.floor(percentOff)}% OFF!
              </>
            )}
          </div>
          <div className="modal-content__desc">
            Discounts starting from {Math.floor(percentOff)}% have already been applied to this
            product
          </div>
          <button className="modal-content__btn" onClick={() => setVisible(false)}>
            Don&apos;t Wait, Shop Now!
          </button>
        </div>
      </div>
    </>
  )
}

const CountdownNotifyModalStyles = css`
  .modal-wrapper {
    padding: 1rem;
    .modal-content {
      padding: 40px 24px;
      text-align: center;
      background-color: #ffffff;

      &__title {
        font-weight: 600;
        font-size: 34px;
        line-height: 40px;
        letter-spacing: -0.04em;
        color: #d32f2f;
        text-transform: uppercase;
        /* padding: 0 24px; */
      }

      &__desc {
        margin-top: 8px;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;

        color: #363636;
        /* padding: 0 24px; */
      }

      &__btn {
        cursor: pointer;
        width: 100%;
        border: 0;
        outline: 0;
        margin-top: 16px;
        padding: 14px 24px;
        gap: 10px;
        font-weight: 600;
        font-size: 17px;
        line-height: 30px;

        background: #282828;
        border-radius: 4px;
        color: #ffffff;
      }
    }
  }

  @media screen and (min-width: 769px) {
    .modal-wrapper.is-active {
      .modal-content {
        width: 480px;

        &__title {
          padding: 0px;
          margin-top: 12px;
        }

        &__desc {
          margin-top: 12px;
          padding: 0 35px;
          font-size: 18px;
        }

        &__btn {
          margin-top: 24px;
        }
      }
    }
  }
`
