import { NextPage } from 'next'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import css from 'styled-jsx/css'

import { store_type } from '@libs/common/models/constant'
import { logger } from '@ui/analytics'
import Catalog from '@ui/components/ministore/Catalog'
import ProductForm from '@ui/components/product/ProductForm'
import ProductImages from '@ui/components/product/ProductImages'
import Reviews from '@ui/components/product/Reviews'
import TermPolicy from '@ui/components/product/TermPolicy'
import Description from '@ui/components/shared/Description'
import Footer from '@ui/components/shared/Footer'
import Header from '@ui/components/shared/Header'
import StarRating from '@ui/components/shared/StarRating'
import { getAvarageRated, parsePageVersion } from '@ui/helpers'
import {
  useAgents,
  useFetchSellPage,
  usePageVersionCookie,
  useRatings,
  useReviews,
  useSetting,
  useSyncedState,
  useVersionCookie
} from '@ui/hooks'
import { useNotiContext } from '@ui/components/shared/Notification'
import Loading from '@ui/components/shared/Loading'
import {
  expiredDiscountKey,
  isPayingWithPaypalKey,
  useGlobalState,
  useLastPLabelItem,
  useLocalDiscountCode,
  useLocalShippingAddress
} from '@libs/client'
import dayjs from 'dayjs'
import getLogRocket from '@ui/analytics/logRocket'
import { getAddress } from '@ui/services/sync-address'
import getConfig from 'next/config'
import { useOrderService } from '@ui/services/shophelp'
import { GoodPolicyBadge } from '@ui/components/shared/GoodPolicyBadge'
import { CustomerAddress } from '@libs/client/order/services'

type CustomCustomerAddress = CustomerAddress & { product_label?: string }

const IndexPage: NextPage = () => {
  const [storeSetting] = useSetting('store')
  const [sellpageRatings] = useRatings()
  const [sellpageSetting, setStore] = useSetting('sellpage')
  const [reviews] = useReviews()
  const isMultiStore = storeSetting?.general?.type === store_type.MINISTORE
  const [, setLocalDiscountCode] = useLocalDiscountCode()

  const avgRated = getAvarageRated(reviews)
  const [isPayingWithPaypal] = useGlobalState<boolean>(isPayingWithPaypalKey)
  const [, setIsExpiredDiscount] = useGlobalState<boolean>(expiredDiscountKey)
  const [, , getActiveImageSyncedProp] = useSyncedState<number>(0)
  const [changedVariantImage, , getChangedVariantImageSyncedProp] = useSyncedState<number>(0)

  const router = useRouter()
  const subpath = router.query.subpath?.toString()
  const error = router.query.error?.toString()
  const { notiDispatch } = useNotiContext()
  const [loading] = useFetchSellPage({ storeSetting, sellpageSetting, setStore })
  const version = useVersionCookie()
  const pversion = usePageVersionCookie()
  const [localShipping, setLocalShipping] = useLocalShippingAddress()
  const [_lastPLabelItem, setLastPLabelItem] = useLastPLabelItem()
  const { getShipping } = useOrderService(getConfig().publicRuntimeConfig.shophelpBasePathApi)
  const isPreOrder = sellpageSetting?.settings?.general?.pre_order

  useEffect(() => {
    if (storeSetting?.general?.logrocket) {
      getLogRocket().logRocket?.init()
    }
  }, [])

  useEffect(() => {
    // logger.logViewProduct(router)
    logger.logProductEvent('view_product')
    if (error === 'offer-expired') {
      notiDispatch({
        payload: {
          content: 'Your order is expired!',
          type: 'is-warning',
          removable: false,
          timeout: 1000
        },
        type: 'REMOVE_ALL_AND_ADD'
      })
      router.replace('/')
    }
  }, [])

  useEffect(() => {
    if (version === 'v144') {
      logger.logProductEvent('version_v144')
    }
    if (version === 'e144') {
      logger.logProductEvent('version_e144')
    }
    if (version === 'vcdn3') {
      logger.logProductEvent('version_vcdn3')
    }
    if (version === 'acdn3') {
      logger.logProductEvent('version_acdn3')
    }
    if (version === 'vna2') {
      logger.logProductEvent('version_vna2')
    }
    if (version === 'gp01a') {
      logger.logProductEvent('version_gp01a')
    }
    if (version === 'gp01b') {
      logger.logProductEvent('version_gp01b')
    }
    if (version === 'gp02') {
      logger.logProductEvent('version_gp02')
    }
    if (version === 'o144') {
      logger.logProductEvent('version_o144')
    }
    if (version === 'vpo1') {
      logger.logProductEvent('version_vpo1')
    }
    if (version === 'g144') {
      logger.logProductEvent('version_g144')
    }
    if (version === 'x144') {
      logger.logProductEvent('version_x144')
    }
    if (version === 'z144') {
      logger.logProductEvent('version_z144')
    }
    if (version === 'k144') {
      logger.logProductEvent('version_k144')
    }
    if (version === 'f144') {
      logger.logProductEvent('version_f144')
    }
    if (version === 'r144') {
      logger.logProductEvent('version_r144')
    }
    if (version === 'm144') {
      logger.logProductEvent('version_m144')
    }
    if (version === 'i144') {
      logger.logProductEvent('version_i144')
    }
    if (version === 'l144') {
      logger.logProductEvent('version_l144')
    }
    if (version === 'usp1') {
      logger.logProductEvent('version_usp1')
    }
    if (version === 'usp2') {
      logger.logProductEvent('version_usp2')
    }
    if (version === 'usp3') {
      logger.logProductEvent('version_usp3')
    }
    if (version === 'usp4') {
      logger.logProductEvent('version_usp4')
    }
    if (version === 'vlz1') {
      logger.logProductEvent('version_vlz1')
    }
    if (version === 'vpl1') {
      logger.logProductEvent('version_vpl1')
    }
    if (version === 'vli1') {
      logger.logProductEvent('version_vli1')
    }
  }, [version])

  useEffect(() => {
    if (pversion) {
      const numberPVersion = parsePageVersion(Number(pversion.split('_')?.[1]) || 0)
      logger.logPageVersion(numberPVersion || '')
    }
  }, [pversion])

  const getShippingAddress = async (sclid: string) => {
    const address: CustomCustomerAddress | undefined = await getAddress(sclid)
    if (address) {
      if (!localShipping) {
        setLocalShipping(address)
      }
      setLastPLabelItem(address?.product_label || '')
    }
  }

  const getShippingAddressInPo = async (id: string) => {
    try {
      if (id) {
        const address = await getShipping(id)
        if (address) {
          setLocalShipping(address)
        }
      }
    } catch (error) {
      console.error('Error get shipping address for RMKT', error)
    }
  }

  useEffect(() => {
    const promo = router.query?.p?.toString()
    const sclid = router.query?.sclid?.toString()
    const utm_source_email = router.query?.utm_source?.toString()
    const utm_source_sms = router.query?.s?.toString()
    const poId = router.query?.po_id?.toString() || router.query?.d?.toString()
    if (sclid && !poId) {
      getShippingAddress(sclid)
    }
    if (utm_source_email == 'selless' || utm_source_sms == 's') {
      getShippingAddressInPo(poId || '')
    }
    if (
      router.query.utm_source === 'selless' &&
      promo &&
      !!router.query.utm_content?.length &&
      router.query.utm_content?.toString().startsWith('e_')
    ) {
      const expiry = router.query?.expiry?.toString()
      if (expiry) {
        const nowTime = dayjs(new Date())
        const expiryTime = dayjs(new Date(+expiry))
        const offsetInDay = expiryTime.diff(nowTime, 'millisecond')
        if (offsetInDay >= 0) {
          setLocalDiscountCode(promo)
        } else {
          setIsExpiredDiscount(true)
          setLocalDiscountCode('')
        }
      } else {
        setIsExpiredDiscount(true)
        setLocalDiscountCode('')
      }
    } else {
      if (promo) {
        setLocalDiscountCode(promo)
      }
    }
  }, [])

  useEffect(() => {
    if (!loading) {
      if (isMultiStore && subpath && !sellpageSetting?.settings) {
        window.location.href = '/'
        // router.push('/')
      }
    }
  }, [loading])
  const [isNormalBattery, setIsNormalBattery] = useState(true)

  const refIsCheckBattery = useRef(true)

  useEffect(() => {
    setIsNormalBattery(!!refIsCheckBattery.current)
  }, [refIsCheckBattery.current])

  const isIOS = useAgents()
  useEffect(() => {
    if (isIOS == 'IOS') {
      setTimeout(() => {
        const videos = document.getElementsByClassName('video-vap')
        if (videos.length) {
          const handleVideoPlayback = (videoElement: HTMLVideoElement) => {
            const playPromise = videoElement.play()
            if (playPromise !== undefined) {
              playPromise.catch(() => {
                // Handle autoplay failure
                refIsCheckBattery.current = false
                setIsNormalBattery(false)
                logger.logProductEvent('low_battery')
              })
            }
          }

          ;(videos as any)?.forEach((video: HTMLVideoElement) => {
            if (video.readyState >= 2) {
              // Indicates that enough data is available to play
              handleVideoPlayback(video)
            } else {
              video.addEventListener('loadedmetadata', () => handleVideoPlayback(video))
            }
          })
        }
      }, 100)
    }

    // Attempt to play the video
  }, [isIOS])

  return (
    <div className={`product-page ${version || storeSetting?.version}`}>
      <style jsx global>
        {globalStyle}
      </style>
      {isPayingWithPaypal ? (
        <Loading isFullWidth />
      ) : (
        <>
          {!isMultiStore || subpath ? (
            loading ? (
              <Loading isFullWidth />
            ) : sellpageSetting && sellpageSetting.settings ? (
              <>
                <Header haveSaleBar redirectable={false} />
                <div className="wrapper">
                  <Description
                    description={sellpageSetting?.parts?.content?.top}
                    className="section section--small top-description"
                  />
                </div>

                <div
                  className={`product container pr-4-desktop pl-4-desktop ${
                    isPayingWithPaypal ? 'is-hidden' : ''
                  }${version == 'p144' || storeSetting?.version == 'p144' ? 'p144' : ''} `}
                >
                  <div className="columns s-variable is-4-dektop mb-5">
                    <div className="column product-image__section is-half">
                      <ProductImages
                        isNormalBattery={isNormalBattery}
                        activeIndexSyncedProp={getActiveImageSyncedProp()}
                        changedVariantImage={changedVariantImage}
                      />

                      {version == 'gp01b' || storeSetting?.version == 'gp01b ' ? (
                        <GoodPolicyBadge
                          prefix_class="product-form__policy"
                          isShowImage
                          highlightCont="45 days"
                          content="Return & Refund Guarantee"
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="column product-form__section is-half">
                      <div className="product-form__header">
                        {!!avgRated && !isPreOrder && (
                          <div className="product__rated">
                            Rated{' '}
                            <StarRating
                              rating={sellpageRatings?.ratio_rating ?? 4.9}
                              className="stars"
                            />
                          </div>
                        )}
                        {version == 'gp01a' || storeSetting?.version == 'gp01a' ? (
                          <>
                            <GoodPolicyBadge
                              isShowImage={false}
                              prefix_class="product-form__policy"
                              highlightCont="45 days"
                              content="Free Refund"
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <ProductForm
                        activeImageSyncedProp={getActiveImageSyncedProp()}
                        changedVariantImageSyncedProp={getChangedVariantImageSyncedProp()}
                      />
                      {version == 'p144' || storeSetting?.version == 'p144' ? (
                        <div className="p144-line"></div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>

                <div className="wrapper">
                  <Description
                    description={sellpageSetting?.parts?.content?.middle}
                    label="Description"
                    className="section section--medium"
                  />
                </div>

                <div className="wrapper">
                  {!!avgRated && (
                    <div className="container">
                      <div className="columns">
                        <div className="column is-8 is-offset-4">
                          <hr className="m-0" />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="wrapper">
                  <Reviews className="section section--medium" />
                </div>
                <div className="wrapper wrapper--primary">
                  <div className="section section--medium">
                    <TermPolicy />
                  </div>
                </div>

                <div className="wrapper">
                  <Description
                    description={sellpageSetting?.parts?.content?.bottom}
                    className="section section--large"
                  />
                </div>

                <Footer />
              </>
            ) : (
              <></>
            )
          ) : (
            <>
              <Header haveSaleBar />
              <Catalog isNormalBattery={isNormalBattery} />
              <Footer />
            </>
          )}
        </>
      )}
    </div>
  )
}

const globalStyle = css.global`
  .product-page {
    &.gp01a {
      .product-images {
        margin-bottom: 0px;
      }

      @media screen and (max-width: 320px) {
        .product-form__section {
          .product-form__header {
            .product-form__policy {
              margin-left: 7px;
              padding: 0px 0px 0px 7px;

              &--text {
                font-size: 14px;
              }
            }
          }
        }
      }

      .product-form__header {
        display: flex;

        .product-form__policy {
          position: relative;
          padding: 0px 0px 0px 12px;
          margin-left: 13px;

          font-weight: 400;
          font-size: 15px;
          line-height: 24px;

          color: #1b1b1b;

          &--text {
            padding-bottom: 1px;
            display: inline-block;
          }

          &--highlight {
            font-weight: 700;
            color: #1d9f54;
          }

          &::before {
            content: '';
            position: absolute;
            width: 1px;
            height: 16px;
            background-color: #d9d9d9;
            left: -1px;
            top: 4px;
          }
        }

        @media screen and (max-width: 320px) {
          .product__rated {
            .star-rating {
              margin-left: 6px;
            }
          }
          .product-form__policy {
            padding: 0px 0px 0px 7px;
            margin-left: 8px;
            font-size: 14px;
          }
        }

        @media screen and (min-width: 768px) {
          .product-form__policy {
            margin-left: 17px;
            padding: 0px 0px 1px 16px;
          }
        }

        .product-form__policy.is-hidden-desktop {
          @media screen and (min-width: 769px) {
            display: none;
          }
        }
      }
    }

    &.gp01b {
      .product-images {
        margin-bottom: 8px;
      }
      .product-form__policy {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        padding: 4px 0px;
        margin: 0px -12px;

        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        color: #1b1b1b;

        &--highlight {
          font-weight: 700;
          color: #1d9f54;
        }

        &--icon {
          width: 18px;
          margin-right: 6px;
        }
      }
      @media screen and (max-width: 768px) {
        .column.product-form__section {
          padding-top: 8px;
        }
        .column.product-image__section {
          padding-bottom: 0px;
        }
      }
      @media screen and (min-width: 769px) {
        .product-image__section {
          .product-form__policy {
            margin: 0px;
            border-radius: 5px;
          }
        }
      }
    }

    .top-description {
      margin-bottom: 61px;
    }
    .product {
      padding-top: 30px;
      padding-bottom: 105px;

      @media screen and (max-width: 768px) {
        padding-top: 0;
        padding-bottom: 48px;

        &.p144 {
          padding-bottom: 0px;

          .columns {
            margin-bottom: 0px !important;

            .column:nth-child(2) {
              padding-bottom: 0px;
            }

            .p144-line {
              background-color: #e8e8e8;
              height: 8px;
              margin-left: -12px;
              margin-right: -12px;
            }
          }
        }
      }

      &__rated {
        display: flex;
        align-items: center;
        font-size: 12px;

        .stars {
          margin-bottom: 4px;
          margin-left: 12px;
        }
      }
    }

    @media screen and (max-width: 768px) {
      .product-images {
        margin-bottom: 24px;
      }
    }

    .loading-component {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
      height: 100vh;
      width: 100vw;
    }
  }
`

export default IndexPage
