import { iconPath } from '@ui/helpers'
import React from 'react'
import css from 'styled-jsx/css'

interface PolicyProps {
  className?: string
}

const Policy = ({ className = '' }: PolicyProps) => {
  return (
    <div className={`policy-container ${className}`}>
      <div className="policy-container__line">
        <img src={iconPath('shield_check_filled.svg')} alt="shield check" />
        <div className="policy-container__line--text">45-Day Satisfaction Guarantee</div>
      </div>

      <div className="policy-container__line shipping-policy">
        <img src={iconPath('shield_check_filled.svg')} alt="shield check" />
        <div className="policy-container__line--text">Secured and Insured Shipping</div>
      </div>

      <div className="policy-container__line">
        <img src={iconPath('shield_check_filled.svg')} alt="shield check" />
        <div className="policy-container__line--text">24/7 Customer Care</div>
      </div>

      <style jsx>{policyStyle}</style>
    </div>
  )
}

const policyStyle = css`
  .policy-container {
    background-color: #fff;
    padding: 18px 12px 16px;
    margin: 16px -12px 0px -12px;

    &__line {
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      &--text {
        color: #1b1b1b;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }
    }

    .shipping-policy {
      margin: 16px 0px;
    }

    @media screen and (min-width: 768px) {
      margin: 24px 0px 0px 0px;
      border-radius: 6px;
      background-color: transparent;
      padding: 0px;

      .shipping-policy {
        margin: 14px 0px;
      }
    }
  }
`

export default Policy
