import { FC } from 'react'
import css from 'styled-jsx/css'

interface ILoading {
  isFullWidth?: boolean
}

const Loading: FC<ILoading> = ({ isFullWidth = false }) => {
  return (
    <>
      <style jsx>{style}</style>
      <div>
        {!isFullWidth ? (
          <div className="loading-component button p-0 is-loading" />
        ) : (
          <div className="loader-wrapper is-active">
            <div className="loader is-loading" />
          </div>
        )}
      </div>
    </>
  )
}

export default Loading

const style = css`
  .loading-component {
    border: none;
    min-height: 4rem;
    width: 100%;

    &.button.is-loading::after {
      height: 3rem;
      width: 3rem;
      left: calc(50% - 1.5rem);
      top: calc(50% - 1.5rem);
    }

    &.full-height {
      height: 100vh;
    }
  }

  .loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.4);
    opacity: 1;
    z-index: 9999999;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      height: 80px;
      width: 80px;
    }
  }
`
