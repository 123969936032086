import { CustomSyncedProp, useMounted, useSetting, useVersionCookie } from 'src/hooks'
import { FC, useState } from 'react'
import QuantityInput from '@ui/components/shared/QuantityInput'
import { getCurrency, imagePath, sleep, useCdnBasePath, videoPath } from '@ui/helpers'
import { orderVariantStyle } from './cart-modal-style'
import { useLocalOrder, useOrderService, useTrackingService } from '@libs/client/order/hooks'
import { useGtag } from '@ui/hooks/useGtag'
import { logger } from '@ui/analytics'
import { useAxon } from '@ui/hooks/useAxon'

interface IVariants {
  title?: string
}

const Variants: FC<IVariants> = ({ title }) => {
  const [localOrder] = useLocalOrder<Types.Order>()
  const version = useVersionCookie()
  const [sellpageSetting] = useSetting('sellpage')

  const mounted = useMounted()

  return (
    <>
      <style jsx global>
        {orderVariantStyle}
      </style>
      <div
        className={`order-variants ${
          sellpageSetting?.settings?.general?.upsell ? 'has-upsell' : ''
        }`}
      >
        <div className="order-variants__bottom"></div>
        <div className="variant-wrapper">
          {mounted &&
            localOrder?.items?.map((variant, index: number) => {
              return (
                <div className="variant-item" key={index}>
                  {<Variant {...{ title, variant, index, version }} />}
                </div>
              )
            })}
        </div>
      </div>
    </>
  )
}

interface IVariant {
  title?: string
  variant: Types.OrderItem
  index: number
  version: string
}

// const mThumb = thumbImage('w=500,h=500')

function Variant({ title, variant, index }: IVariant) {
  const { removeItems, updateItems } = useOrderService()
  const [sellpageSetting] = useSetting('sellpage')
  const { gtagTrackRemoveFromCart } = useGtag()
  const { axonTrackRemoveFromCart } = useAxon()
  const { trackingRemoveToCart } = useTrackingService()
  const basepath = useCdnBasePath()

  // const [, setIsUpdatingCart] = useGlobalState<boolean>('is_updating_cart')
  const [isLoading, setIsLoading] = useState(false)

  async function onRemove() {
    setIsLoading(true)
    await sleep(500)
    setIsLoading(false)
    removeItems([variant.id])
    gtagTrackRemoveFromCart(variant, variant.quantity)
    axonTrackRemoveFromCart(variant, variant.quantity)
    trackingRemoveToCart(variant, variant.quantity)
    logger.logProductEvent('remove_from_cart')
  }

  function getVariantQuantitySyncedProp(): CustomSyncedProp<number> {
    return [
      variant.quantity ?? 0,
      async (quantity: number) => {
        if (variant) {
          updateItems([{ id: variant.id, quantity }])
        }
      }
    ]
  }
  return (
    <>
      <div className="variant">
        <div className="variant--top columns is-mobile is-variable is-2">
          <div className="column is-4">
            {/* <figure className="variant__image image is-square"> */}
            {/* <img src={mThumb(variant.imageUrl ?? '')} alt="" /> */}
            <figure className="variant__image image is-square">
              {variant.imageUrl?.includes('.mp4') ? (
                <video autoPlay loop muted playsInline className="">
                  <source src={videoPath(variant?.imageUrl || '', basepath)} type="video/mp4" />
                </video>
              ) : (
                <picture>
                  <source srcSet={imagePath(variant.imageUrl ?? '')} />
                  {sellpageSetting?.fallback_img?.[variant.imageUrl || ''] ? (
                    <source
                      srcSet={imagePath(sellpageSetting?.fallback_img?.[variant.imageUrl || ''])}
                    />
                  ) : (
                    <></>
                  )}
                  <img src={imagePath(variant.imageUrl ?? '')} alt="variant product" />
                </picture>
              )}
            </figure>
          </div>
          <div className="column">
            <div className="columns is-vcentered is-variable is-2 mb-0">
              <div className="column pb-1-mobile">
                <div className="variant__title">{title}</div>
                <div className="size--small">
                  <div className="variant__property expand-on-mobile">
                    <p className="property mr-0">
                      {Object.entries(variant.properties ?? {}).map(
                        (property: any, i: number, arr: any[]) => {
                          return (
                            <span
                              key={`${variant.id}_${i}`}
                              className={i != arr.length - 1 ? 'mr-6' : ''}
                            >
                              <strong>{property[0]}</strong>: {property[1]}
                            </span>
                          )
                        }
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="column is-4 pt-1-mobile pb-1-mobile is-hidden-mobile">
                <div className="variant__remove">
                  <a
                    // className={`button is-text has-text-link${isRemoving ? ' is-loading' : ''}`}
                    className={`button is-text has-text-link ${isLoading ? ' is-loading' : ''}`}
                    onClick={() => onRemove()}
                  >
                    Remove
                  </a>
                </div>
              </div>
            </div>
            <div className="variant__quantity desktop is-flex">
              <QuantityInput
                label=""
                isCta={false}
                valueSyncedProp={getVariantQuantitySyncedProp()}
                emitChange={true}
              />
              <div className="variant__price is-pulled-right">
                {sellpageSetting?.settings?.configurations?.show_compared_price &&
                  variant.compare_price &&
                  variant.price &&
                  variant.compare_price > variant.price &&
                  !sellpageSetting?.settings?.configurations?.hide_clearance && (
                    <del className="compared">{getCurrency(variant.compare_price)}</del>
                  )}
                {getCurrency(variant.price ?? 0)}
                {sellpageSetting?.settings?.general?.upsell &&
                  (index > 0 || (index == 0 && (variant?.quantity || 0) > 1)) && (
                    <div className="upsell-save">
                      You saved {getCurrency((variant?.compare_price || 0) - (variant?.price || 0))}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="variant__quantity mobile is-flex">
          <QuantityInput
            label=""
            isCta={false}
            valueSyncedProp={getVariantQuantitySyncedProp()}
            emitChange={true}
            className="mb-0"
          />
          <div className="column is-4 pt-1-mobile pb-1-mobile btn-remove-mobile is-hidden-desktop">
            <div className="variant__remove">
              <a
                // className={`button is-text has-text-link${isRemoving ? ' is-loading' : ''}`}
                className={`button is-text has-text-link ${isLoading ? ' is-loading' : ''}`}
                onClick={() => onRemove()}
              >
                Remove
              </a>
            </div>
          </div>
          <div className="variant__price is-pulled-right">
            {sellpageSetting?.settings?.configurations?.show_compared_price &&
              variant.compare_price &&
              variant.price &&
              variant.compare_price > variant.price &&
              !sellpageSetting?.settings?.configurations.hide_clearance && (
                <del className="compared">{getCurrency(variant.compare_price)}</del>
              )}
            {getCurrency(variant.price ?? 0)}
            {sellpageSetting?.settings?.general?.upsell &&
              (index > 0 || (index == 0 && (variant?.quantity || 0) > 1)) && (
                <div className="upsell-save">
                  You saved {getCurrency((variant?.compare_price || 0) - (variant?.price || 0))}
                </div>
              )}
          </div>
        </div>
      </div>

      <hr />
    </>
  )
}

export default Variants
