import { thumbImage, useGlobalState, useTrackingService } from '@libs/client'
import {
  imagePath,
  moneyConverter,
  useCdnBasePath,
  videoPath,
  videoPathFallback
} from '@ui/helpers'
import { useRedirect, useSetting } from '@ui/hooks'
import { useEffect, useRef } from 'react'
import css from 'styled-jsx/css'
import SfLink from '../next/SfLink'
import Description from '../shared/Description'
import StarRating from '../shared/StarRating'
import { useRouter } from 'next/router'
import { category_type } from '@libs/common/models/constant'

const sThumb = thumbImage('96x96')

const mappingTitle: { [key: string]: string } = {
  clearance: 'CLEARANCE',
  'new-arrivals': 'NEW ARRIVALS',
  bestsellers: 'BESTSELLERS',
  'pre-order': 'PRE-ORDER'
}

const defineCategory = (colection_path?: string | string[]) => {
  if (Array.isArray(colection_path)) return ''
  switch (colection_path) {
    case 'clearance':
      return category_type.clearance
    case 'new-arrivals':
      return category_type['new-arrivals']
    case 'bestsellers':
      return category_type.bestsellers
    case 'pre-order':
      return category_type['pre-order']
    default:
      return ''
  }
}

export default function Catalog({ isNormalBattery }: { isNormalBattery: boolean }) {
  const router = useRouter()
  const category = defineCategory(router?.query?.collection_path)
  const [colection] = useGlobalState<S3Types.collection | null>('store.collection')
  const hasPreOrder = Object.keys(colection || {}).includes('pre-order')
  const [storeSetting] = useSetting('store')
  const redirect = useRedirect()

  const { trackingViewItem } = useTrackingService()
  useEffect(() => {
    //TODO: tracking
    trackingViewItem()
    if (router?.query?.collection_path) {
      if (!Object.keys(mappingTitle)?.includes(category)) {
        redirect('/', { routerOptions: { scroll: false } })
      }
      if (!Object.values(colection?.[category] || {}).length) {
        redirect('/')
      }
    }
  }, [])
  return (
    <div className="ministore-catalog">
      <style jsx global>
        {globalStyle}
      </style>
      <div className="wrapper">
        <div className="page__content section is-max-desktop">
          <h1>{storeSetting?.general?.name} Products</h1>
          {storeSetting?.general?.description && (
            <Description description={storeSetting.general.description} />
          )}
          <div className="stores">
            {!category ? (
              <>
                {colection &&
                  Object.entries(colection).map(([category_path, item]) => (
                    <div key={category_path}>
                      {Object.keys(item)?.length ? (
                        <>
                          <div
                            className={`section-title ${
                              (category_path == category_type.clearance && !hasPreOrder) ||
                              category_path == category_type['pre-order']
                                ? 'clearance-title'
                                : ''
                            }`}
                          >
                            {mappingTitle?.[category_path] || ''}
                          </div>
                          <div className="columns columns-clearance">
                            {item &&
                              Object.entries(item).map(([subpath, item]) => (
                                <div
                                  key={subpath}
                                  className="column is-12-mobile is-6-tablet is-4-desktop"
                                >
                                  {item.soldout ? (
                                    <a className="store-card inactive">
                                      <CatalogItem
                                        isNormalBattery={isNormalBattery}
                                        item={item}
                                        category={category_path}
                                      />
                                    </a>
                                  ) : (
                                    <SfLink pathName={`/${subpath}`} className="store-card">
                                      <CatalogItem
                                        isNormalBattery={isNormalBattery}
                                        item={item}
                                        category={category_path}
                                      />
                                    </SfLink>
                                  )}
                                </div>
                              ))}
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  ))}
              </>
            ) : (
              <>
                <div
                  className={`section-title ${
                    category == category_type.clearance || category == category_type['pre-order']
                      ? 'clearance-title'
                      : ''
                  }`}
                >
                  {mappingTitle?.[category] || ''}
                </div>
                <div className="columns">
                  {colection?.[category] &&
                    Object.entries(colection?.[category]).map(([subpath, item]) => (
                      <div key={subpath} className="column is-12-mobile is-6-tablet is-4-desktop">
                        {item.soldout ? (
                          <a className="store-card inactive">
                            <CatalogItem
                              isNormalBattery={isNormalBattery}
                              item={item}
                              category={category}
                            />
                          </a>
                        ) : (
                          <SfLink pathName={`/${subpath}`} className="store-card">
                            <CatalogItem
                              isNormalBattery={isNormalBattery}
                              item={item}
                              category={category}
                            />
                          </SfLink>
                        )}
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const CatalogItem = ({
  item,
  category,
  isNormalBattery
}: {
  item: S3Types.catalog_item
  category?: string
  isNormalBattery: boolean
}) => {
  const ref = useRef(null)
  const basepath = useCdnBasePath()
  useEffect(() => {
    window?.addEventListener('touchstart', () => {
      const videoElement: any = document?.getElementsByTagName('video')
      if (videoElement?.length) {
        ;[...videoElement]?.forEach(async (element: HTMLMediaElement) => {
          if (!element?.controls) {
            element?.play()
          }
        })
      }
    })
    return () => {
      window.removeEventListener('touchstart', () => {
        console.log('clean')
      })
    }
  }, [ref])

  return (
    <>
      <figure className="thumbnail">
        {(item.logo ?? '').includes('.mp4') ? (
          <>
            {!isNormalBattery ? (
              <picture>
                <source data-srcset={videoPathFallback(item.fallback_logo ?? '')} />
                <img
                  className={`lazyload`}
                  src={sThumb(item.fallback_logo ?? '')}
                  data-src={videoPathFallback(item.fallback_logo ?? '')}
                  alt="carousel"
                />
              </picture>
            ) : (
              <video
                autoPlay
                loop
                muted
                playsInline
                className="video-vap"
                poster={sThumb(item.fallback_logo ?? '')}
              >
                <source src={videoPath(item.logo ?? '', basepath)} type="video/mp4" />
              </video>
            )}{' '}
          </>
        ) : (
          <picture>
            <source data-srcset={imagePath(item.logo ?? '')} />
            {item.fallback_logo ? (
              <source data-srcset={imagePath(item.fallback_logo ?? '')} />
            ) : (
              <></>
            )}
            <img
              src={sThumb(item.logo ?? '')}
              data-src={(() => {
                const image = item.logo ?? ''
                return imagePath(image)
              })()}
              alt="item thumb"
              className="lazyload"
            />
          </picture>
        )}
      </figure>
      <div className="content">
        <div className="info">
          <StarRating rating={item.review_score} />
          <h3 className="title">{item.title}</h3>
          <div className="price">
            <div className="price-item">
              <span className="default">$ {moneyConverter(item.price ?? 0)}</span>
              {!item?.hide_clearance ? (
                <span className="compared">$ {moneyConverter(item.compare_price ?? 0)}</span>
              ) : (
                <></>
              )}
            </div>
            {!item?.hide_clearance &&
            (category == category_type.clearance || category == category_type['pre-order']) ? (
              <>
                <div className="clearance">
                  <div className="clearance__label">
                    {category == category_type.clearance ? 'Clearance' : 'PRE-ORDER'}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="view-button">
          <button className="button is-primary">
            <span>{item.soldout ? 'Sold Out' : 'View Product'}</span>
          </button>
        </div>
      </div>
    </>
  )
}

const globalStyle = css.global`
  .ministore-catalog {
    display: block;

    .page__content {
      max-width: 1120px;
      padding-top: 32px;
      padding-bottom: 96px;
    }

    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 40px;
      margin-bottom: 8px;
    }

    .stores {
      margin-top: 48px;
    }

    .columns {
      flex-wrap: wrap;
      grid-row-gap: 32px;
      &.columns-clearance {
        grid-row-gap: 24px;
      }
    }

    .section-title {
      color: #1b1b1b;
      font-size: 26px;
      font-weight: 700;
      line-height: 40px;
      text-transform: uppercase;

      margin-bottom: 16px;
      margin-top: 48px;

      &.clearance-title {
        color: #de2323;
      }
    }

    .store-card {
      background-color: #fff;
      border: 1px solid #eaecf0;
      border-radius: 6px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      height: 100%;

      .thumbnail {
        height: 284px;
        overflow: hidden;

        img {
          width: 100%;
          object-fit: cover;
        }
        video[poster] {
          width: 100%;
          object-fit: fill;
        }
      }

      .content {
        position: relative;
        flex: 1;
      }

      .info {
        padding: 16px 24px 24px;
        transition: opacity 0.2s ease-in-out;
        height: 100%;
        display: flex;
        flex-direction: column;

        .star-rating {
          margin-bottom: 8px;
        }

        .title {
          margin: 0 0 8px;
          font-size: 15px;
          font-weight: normal;
          line-height: 32px;
          color: #657381;
          flex: 1;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .price {
          display: flex;
          align-items: flex-end;

          .default {
            color: #464f5b;
            font-size: 24px;
            font-weight: 600;
            line-height: 40px;
            margin-right: 12px;
          }

          .compared {
            font-size: 18px;
            font-weight: 300;
            line-height: 36px;
            text-decoration-line: line-through;
            color: #939eab;
          }
        }
      }

      .view-button {
        transition: opacity 0.3s ease-in-out;
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          height: 48px;
          width: 200px;
          max-width: 90%;
        }
      }

      .clearance {
        position: absolute;
        right: 0;
        bottom: 32px;
        &__label {
          background: #ef5350;
          border-radius: 4px;
          padding: 0 8px;
          letter-spacing: 0.02em;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: #ffffff;
        }
      }

      &:hover {
        .info {
          opacity: 0;
        }

        .view-button {
          opacity: 1;
        }
      }

      &.inactive {
        cursor: default;
        button {
          cursor: default;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    .clearance {
      margin-left: auto;
      &__label {
        letter-spacing: 0.28px;
      }
    }

    .ministore-catalog {
      .section-title {
        margin-top: 64px;
        font-size: 24px;
        &.clearance-title {
          margin-top: 40px;
        }
      }
    }
  }
`
