import { useSetting } from '@ui/hooks'
import { FC, useMemo } from 'react'
import css from 'styled-jsx/css'
import SfLink from '../next/SfLink'

const TermPolicy: FC<Types.PlainObject> = () => {
  const [storeSetting] = useSetting('store')
  const title = storeSetting?.general?.name || ''
  const url = storeSetting?.general?.domain || ''
  const shop = useMemo(() => {
    let _url = url ?? ''
    _url = _url.replace(/^(https?:\/\/)?/, 'https://')

    const domain = _url.replace(/^https?:\/\/?/, '')
    return (
      title ||
      domain.replace(/^(https?:\/\/)?((.+)\.(onselless\.|mycellas\.)(.+)|(.+)\.(.+))/, '$3$6')
    )
  }, [title, url])

  return (
    <div className="term-policy">
      <style jsx global>
        {globalStyle}
      </style>
      <div className="columns">
        <div className="column is-4">
          <div className="title--large">Shipping & Returns</div>
        </div>
        <div className="column is-8 static-content rich-text">
          <div className="term-hardcode">
            <p>
              <span className="shop__name">{shop}</span> stands by our product quality and offers
              returns, refunds, and exchanges. Please see our policy details{' '}
              <SfLink pathName="/static/exchanges-returns">here</SfLink>. You may also learn more
              about shipping FAQs <SfLink pathName="/static/orders-shipping">here</SfLink>.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const globalStyle = css.global`
  .term-policy {
    .static-content {
      a {
        color: #2d9cdb;
        text-decoration: underline;

        &.is-phone {
          color: var(--text);
          text-decoration: unset;
        }
      }
    }
    .shop__name {
      color: #2d9cdb;
    }

    .term-hardcode {
      padding-top: 15px;
      @media screen and (max-width: 768px) {
        padding-top: 0px;
        margin-top: -32px;
      }
    }
  }
`

export default TermPolicy
